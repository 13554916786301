<div class="main-sidebar sidebar-style-2">
  <aside id="sidebar-wrapper">
    <div class="sidebar-brand">
      <a href="/"><span
        class="logo-name-custom logo-name"><span style="color: #ffda10">o</span>nbir liqa</span>
      </a>
    </div>
    <ul class="sidebar-menu">
      <li class="menu-header"><a><span>Menyu</span></a></li>
      <li class="dropdown" *ngFor="let nav of navItems">
        <a [routerLink]="nav?.url" class="nav-link" *ngIf="checkAccess(nav?.role)"><i
          [ngClass]="nav?.logo"></i><span>{{nav?.text}}</span></a>
      </li>
    </ul>
  </aside>
</div>
