import {ErrorHandler, Injectable} from '@angular/core';
import {ModalService} from './modal.service';
import {Subject} from 'rxjs';

declare var $: any;

@Injectable({
  providedIn: 'root'
})
export class ExceptionHandlerService {
  errorModalVisibilityChange: Subject<boolean> = new Subject<boolean>();

  showErrorModal = false;

  handleError(error: any, message: string, title: string): void {
    console.log('Error');
    this.modalService.openInfoModal(message, title);
  }

  constructor(private modalService: ModalService) {
    this.errorModalVisibilityChange.subscribe((value) => {
      this.showErrorModal = value;
    });
  }


}
