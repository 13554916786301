import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree} from '@angular/router';
import {AuthService} from './auth.service';
import {Observable} from 'rxjs';
import {RoleService} from './role.service';
import {NavigationService} from '../utils/navigation.service';
import {ProfileService} from '../profile/profile.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuardService implements CanActivate {

  constructor(private authorizationService: AuthService,
              private router: Router,
              private roleService: RoleService,
              private profileService: ProfileService) {
  }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean | UrlTree {
    this.checkHaveRoleToPage(next);
    if (this.authorizationService.isLoggedIn()) {
      this.roleService.wrapRoles();
      this.authorizationService.ping();
      this.profileService.wrapDetails();
      return true;
    } else {
      return this.router.parseUrl('auth/login');
    }
  }

  checkHaveRoleToPage(route: ActivatedRouteSnapshot): void {
    const roles = localStorage.getItem('authorities');
    const nextPath = route.routeConfig.path;
    // this.navigationService.redirectToCompanyIndex();
  }
}
