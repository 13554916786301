import {Injectable} from '@angular/core';
import {CustomHttpClient} from '../../client/CustomHttpClient';
import {environment} from '../../../environments/environment';
import {GeneralResponse} from '../../model/GeneralRespone';
import {ResponseCodes} from '../../enum/ResponseCodes';
import {UserDetailResponse} from '../../model/user/user-detail-response';

declare var $: any;

@Injectable({
  providedIn: 'root'
})
export class UtilService {
  private response: GeneralResponse;

  constructor(private http: CustomHttpClient) {
  }

  validatePassword(newPassword: string, newPasswordRepeat: string): boolean {
    if (newPassword === newPasswordRepeat) {
      return true;
    } else {
      return false;
    }
  }

  wrapUserDetails(): void {
    this.http.get(environment.ENDPOINTS.USER_DETAILS, null, false, false, false).subscribe(data => {
      this.response = data;
      if (this.response.code === ResponseCodes.OK) {
        localStorage.setItem('user', JSON.stringify(this.response.result.data));
      }
    });
  }

  getUserInfo(): UserDetailResponse {
    return JSON.parse(localStorage.getItem('details'));
  }

  getImage(avatarPath): string {
    return environment.ENDPOINTS.GET_IMAGE.replace(':slug', avatarPath);
  }

  showLoader(): void {
    $('.loader').fadeIn('slow');
  }

  hideLoader(): void {
    $('.loader').fadeOut('slow');
  }
}
