import {Injectable} from '@angular/core';
import {NavigationEnd, Router} from '@angular/router';
import {filter} from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class NavigationService {
  private previousUrl: string;

  constructor(private router: Router) {
  }

  getPreviousPage(): string {
    this.router.events
      .pipe(filter(event => event instanceof NavigationEnd))
      .subscribe((event: NavigationEnd) => {
        this.previousUrl = event.url;
      });
    return this.previousUrl;
  }

  redirectToLogin(): void {
    this.router.navigateByUrl('auth/login');
  }

  redirectToResetPassword(): void {
    this.router.navigateByUrl('/auth/reset-password');
  }

  redirectToIndex(): void {
    window.location.href = '/';
  }

  redirectToCompanyIndex(): void {
    this.router.navigateByUrl('/');
  }
}
