<div class="loader"></div>
<div id="app">
  <div class="main-wrapper main-wrapper-1">
    <app-navbar *ngIf="showNavbar"></app-navbar>
    <app-left-sidebar *ngIf="showSidebar"></app-left-sidebar>
    <div class="container-fluid">
      <div class="row">
        <div class="col-12">
          <div [ngClass]="{'main-content': showSidebar}">
            <router-outlet></router-outlet>
          </div>
        </div>
      </div>
    </div>
    <!--        ExceptionModal-->
    <div class="modal fade show" id="infoModal" tabindex="-1" role="dialog"
         aria-labelledby="exampleModalCenterTitle" style="display: none; padding-right: 17px;" aria-modal="true">
      <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="infoModalTitle">Xəta</h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">×</span>
            </button>
          </div>
          <div class="modal-body">
            <p id="infoModalMessage"></p>
          </div>
          <div class="modal-footer bg-whitesmoke br">
            <button type="button" class="btn btn-secondary" data-dismiss="modal">Bağla</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
