import {Injectable} from '@angular/core';

declare var $: any;

@Injectable({
  providedIn: 'root'
})
export class ModalService {
  openInfoModal(message: string, title: string): void {
    $('#infoModalTitle').text(title);
    $('#infoModalMessage').text(message);
    $('#infoModal').modal('show');
  }
}
