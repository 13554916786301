<div class="navbar-bg"></div>
<nav class="navbar navbar-expand-lg main-navbar sticky">
  <div class="form-inline mr-auto">
    <ul class="navbar-nav mr-3">
      <li><a data-toggle="sidebar" (click)="openLeftSidebar()" class="nav-link nav-link-lg
									collapse-btn"> <i data-feather="align-justify"></i></a></li>
      <li><a href="#" class="nav-link nav-link-lg fullscreen-btn">
        <i data-feather="maximize"></i>
      </a></li>
    </ul>
  </div>
  <ul class="navbar-nav navbar-right">
    <li>
      <a href="https://www.facebook.com/onbirazz" target="_blank" class="btn btn-social-icon mr-1 btn-facebook">
        <i class="fab fa-facebook-f"></i>
      </a>
    </li>
    <li>
      <a href="https://www.instagram.com/onbiraz" target="_blank" class="btn btn-social-icon mr-1 btn-instagram">
        <i class="fab fa-instagram"></i>
      </a>
    </li>
    <li>
      <a href="https://t.me/onbiraz" target="_blank" class="btn btn-social-icon mr-1 btn-telegram">
        <i class="fab fa-telegram-plane"></i>
      </a>
    </li>
    <li class="dropdown"><a href="#" data-toggle="dropdown"
                            class="nav-link dropdown-toggle nav-link-lg nav-link-user"> <img alt="image"
                                                                                             [src]="!getImage() ?'assets/img/users/default.png':getImage()"
                                                                                             height="30"
                                                                                             class="user-img-radious-style">
      <span class="d-sm-none d-lg-inline-block"></span></a>
      <div class="dropdown-menu dropdown-menu-right pullDown">
        <div class="dropdown-title">Salam, {{' ' + utilService.getUserInfo().name}}</div>
        <a [routerLink]="'/profile'" class="dropdown-item has-icon"> <i class="far
										fa-user"></i> Profil
        </a> <a [routerLink]="'/competition/history'" class="dropdown-item has-icon"> <i class="fas fa-bolt"></i>
        Tarixçə
      </a>
        <div class="dropdown-divider"></div>
        <a (click)="logout()" href="javascript:void(0)" class="dropdown-item has-icon text-danger">
          <i
            class="fas fa-sign-out-alt"></i>
          Çıxış
        </a>
      </div>
    </li>
  </ul>
</nav>
