import {Injectable} from '@angular/core';
import {CustomHttpClient} from '../../client/CustomHttpClient';
import {environment} from '../../../environments/environment';
import {GeneralResponse} from '../../model/GeneralRespone';

@Injectable({
  providedIn: 'root'
})
export class RoleService {

  constructor(private http: CustomHttpClient) {
  }

  wrapRoles(): void {
    this.http.get(environment.ENDPOINTS.ROLES, null, false, false).toPromise().then(r => {
      localStorage.setItem('authorities', r.result.data);
    });
  }
}
