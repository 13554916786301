export class ResultResponse {
  totalCount: number;
  data: any;

  constructor(totalCount: number, data: any) {
    this.totalCount = totalCount;
    this.data = data;
  }
}


export class GeneralResponse {
  status: string;
  code: number;
  message: string;
  result: ResultResponse;

  constructor(status: string, code: number, message: string, result: ResultResponse) {
    this.status = status;
    this.code = code;
    this.message = message;
    this.result = result;
  }
}

