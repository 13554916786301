import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {AuthGuardService} from './service/auth/auth-guard.service';

const routes: Routes = [

  {
    path: 'auth',
    loadChildren: () => import('./modules/auth/auth.module').then(m => m.AuthModule),
    data: {showNavbar: false, showSideBar: false}
  },
  {
    path: 'profile',
    canActivate: [AuthGuardService],
    loadChildren: () => import('./modules/profile/profile.module').then(m => m.ProfileModule),
    data: {showNavbar: true, showSideBar: true}
  },
  {
    path: 'competition',
    canActivate: [AuthGuardService],
    loadChildren: () => import('./modules/competition/competition.module').then(m => m.CompetitionModule),
    data: {showNavbar: true, showSideBar: true}
  },
  {
    path: '',
    loadChildren: () => import('./modules/dashboard/dashboard.module').then(m => m.DashboardModule),
    canActivate: [AuthGuardService],
    data: {showNavbar: true, showSideBar: true}
  },
  {
    path: 'admin',
    loadChildren: () => import('./modules/admin/admin.module').then(m => m.AdminModule),
    canActivate: [AuthGuardService],
    data: {showNavbar: true, showSideBar: true}
  },
  {
    path: '404', loadChildren: () => import('./modules/error/error.module').then(m => m.ErrorModule),
    data: {showNavbar: false, showSideBar: false}
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {useHash: true})],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
