import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {CustomHttpClient} from '../../client/CustomHttpClient';
import {environment} from '../../../environments/environment';
import {GeneralResponse} from '../../model/GeneralRespone';
import {LogoutRequest} from '../../model/auth/logout-request';
import {ResponseCodes} from '../../enum/ResponseCodes';
import {NavigationService} from '../utils/navigation.service';
import {ExceptionHandlerService} from '../utils/exception-handler.service';
import {ResetPasswordRequest} from '../../model/auth/reset-password-request';
import {UtilService} from '../utils/util.service';
import * as swal from '../../../assets/bundles/sweetalert/sweetalert.min';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  constructor(private http: CustomHttpClient,
              private navigationService: NavigationService,
              private exceptionHandler: ExceptionHandlerService,
              private utilService: UtilService) {
  }

  isLoggedIn(): boolean {
    return !!localStorage.getItem('Authorization');
  }


  login(body: any): any {
    this.http.post(environment.ENDPOINTS.LOGIN, null, body, undefined, true).subscribe(data => {
      if (data.code === ResponseCodes.OK) {
        localStorage.setItem('Authorization', 'bearer ' + data.result.data.token);
        this.navigationService.redirectToIndex();
      } else {
        if (!swal.getState().isOpen) {
          swal('Xəta', data.message, 'error');
        }
      }
    });
  }

  submitLogin(body: any): Observable<any> {
    return this.http.post(environment.ENDPOINTS.LOGIN, null, body, undefined, true);
  }

  getResetLink(body: any): Observable<GeneralResponse> {
    return this.http.post(environment.ENDPOINTS.FORGOT_PASSWORD, GeneralResponse, body, undefined, true);
  }

  logout(): void {
    const logoutRequest = new LogoutRequest();
    logoutRequest.token = localStorage.getItem('Authorization');
    logoutRequest.fcmToken = ' ';
    // this.http.post(environment.ENDPOINTS.LOGOUT, GeneralResponse, logoutRequest, null, false).subscribe(data => {
    //   if (data.data.code === ResponseCodes.OK) {
    //
    //   }
    // });
    localStorage.removeItem('Authorization');
    this.navigationService.redirectToLogin();
  }

  forgotPassword(forgotPasswordRequest): void {
    localStorage.setItem('tempUser', forgotPasswordRequest.username);
    this.http.post(environment.ENDPOINTS.FORGOT_PASSWORD, GeneralResponse, forgotPasswordRequest, null, true).subscribe(data => {
      if (data.code === ResponseCodes.OK) {
        this.navigationService.redirectToLogin();
      } else {
      }
    });
  }

  resetPassword(resetPasswordRequest: ResetPasswordRequest): void {
    this.http.post(environment.ENDPOINTS.RESET_PASSWORD, GeneralResponse, resetPasswordRequest, null, true).subscribe(data => {
      if (data.code === ResponseCodes.OK) {
        this.navigationService.redirectToLogin();
      } else {
        this.exceptionHandler.handleError('X', data.message, 'Xəta');
      }
    });
  }

  ping(): void {

    // let response = this.http.get(environment.ENDPOINTS.PING, null, false, null).toPromise();
    // response.then(data => {
    //   console.log(data);
    // });
  }

  register(body): Observable<any> {
    return this.http.post(environment.ENDPOINTS.REGISTER, GeneralResponse, body, null, false);
  }

  verify(token): Observable<GeneralResponse> {
    return this.http.get(environment.ENDPOINTS.VERIFY.replace(':token', token), null, false, false);
  }
}
