import {Injectable} from '@angular/core';
import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpResponse
} from '@angular/common/http';
import {Observable, throwError} from 'rxjs';
import {catchError, finalize, map, retry} from 'rxjs/operators';
import * as swal from '../assets/bundles/sweetalert/sweetalert.min';
import {NavigationService} from './service/utils/navigation.service';
import {GeneralResponse} from './model/GeneralRespone';
import {ResponseCodes} from './enum/ResponseCodes';

declare const $: any;

@Injectable()
export class CustomHttpInterceptor implements HttpInterceptor {
  constructor(private navigationService: NavigationService) {
  }

  serverError: GeneralResponse;

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    // Add Auth Token
    // In production you would get the token value from an auth service
    const hardcodedToken = localStorage.getItem('Authorization');
    req = req.clone({
      setHeaders: {
        Authorization: `${hardcodedToken}`
      }
    });
    return next.handle(req)
      .pipe(
        retry(2),
        map((event: HttpEvent<any>) => {
          if (event instanceof HttpResponse) {
            if (event.body.code === ResponseCodes.ACCESS_DENIED) {
              localStorage.removeItem('Authorization');
              this.navigationService.redirectToLogin();
            }
            if (req?.method === 'POST' && event.body.code === ResponseCodes.OK) {
              if (!swal.getState().isOpen) {
                if (event.body.message) {
                  swal('Məlumat', event.body.message, 'success');
                } else {
                  swal('Məlumat', 'Əməliyyat uğurla tamamlandı', 'success');
                }
              }
            }
          }
          return event;
        }),
        catchError((error: HttpErrorResponse) => {
          // TODO: Add error handling logic here
          console.log(error);
          if (error.status === 0) {
            if (!swal.getState().isOpen) {
              swal('Xəta', 'Serverlə əlaqə yaratmaqda problem yaşandı...', 'error');
            }
          } else {
            this.serverError = error.error;
            swal('Xəta', this.serverError.message, 'error');
          }
          return throwError(error);
        }),
        // PROFILING
        finalize(() => {
        })
      );
  }

}
