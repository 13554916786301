// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.
const API_URL = 'https://api.onbir.io/';
const AUTH = 'public/authorization/';
const ADMIN = API_URL + 'private/admin/';
export const environment = {
  production: false,
  ENDPOINTS: {
    IMAGE_DISPLAYER: API_URL + 'public/image?url=',
    LOGIN: API_URL + AUTH + 'login',
    FORGOT_PASSWORD: API_URL + AUTH + 'forgot-password',
    LOGOUT: API_URL + AUTH + 'logout',
    RESET_PASSWORD: API_URL + AUTH + 'reset-password',
    REGISTER: API_URL + AUTH + 'registration',
    VERIFY: API_URL + AUTH + 'verify/:token',
    PING: API_URL + 'private/ping',
    ROLES: API_URL + 'private/user-detail/authorities',
    USER_DETAILS: API_URL + 'private/user-detail/self',
    STANDING: API_URL + 'private/standing/search',
    USER_STANDING: API_URL + 'private/standing/self',
    GAME: {
      OPEN_GAMES: API_URL + 'private/tour/games',
      ANSWER: API_URL + 'private/answer',
      HISTORY: API_URL + 'private/answer/history'
    },
    ADMIN: {
      TOUR_LIST: ADMIN + 'tour/list',
      TOUR_DELETE: ADMIN + 'tour/delete/:id',
      TOUR_ADD: ADMIN + 'tour',
      GAME_LIST: ADMIN + 'games/tour',
      GAME: ADMIN + 'games',
      ADD_FINAL_RESULT: ADMIN + 'games/answer',
      DELETE_GAME: ADMIN + 'games/delete/:id',
      MATCHES: ADMIN + 'match/list',
      MATCH_CREATE: ADMIN + 'match/create',
      MATCHES_UPDATE: ADMIN + 'match/update/:id',
      MATCH_DELETE: ADMIN + 'match/delete/:id',
      GET_MATCH: ADMIN + 'match/get/:id',
      CHANNELS: ADMIN + 'channel/list',
      CHANNEL_CREATE: ADMIN + 'channel/create',
      CHANNEL_UPDATE: ADMIN + 'channel/update/:id',
      CHANNEL_DELETE: ADMIN + 'channel/delete/:id',
      GET_CHANNEL: ADMIN + 'channel/get/:id',
      TEAMS: ADMIN + 'teams/list'

    },
    USER_AVATAR: API_URL + 'private/user-avatar/upload',
    GET_IMAGE: API_URL + 'public/files/show/:slug'
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
