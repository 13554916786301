import {Injectable} from '@angular/core';
import {CustomHttpClient} from '../../client/CustomHttpClient';
import {Observable} from 'rxjs';
import {environment} from '../../../environments/environment';
import {GeneralResponse} from '../../model/GeneralRespone';
import {ResponseCodes} from '../../enum/ResponseCodes';
import {HttpParams} from "@angular/common/http";

@Injectable({
    providedIn: 'root'
})
export class ProfileService {

    constructor(private http: CustomHttpClient) {
    }

    getUserDetails(): Observable<any> {
        return this.http.get(environment.ENDPOINTS.USER_DETAILS, null, false);
    }

    saveDetails(user): Observable<any> {
        return this.http.post(environment.ENDPOINTS.USER_DETAILS, GeneralResponse, user, null, false, false);
    }

    wrapDetails(): void {
        this.getUserDetails().subscribe(data => {
            if (data.code === ResponseCodes.OK) {
                localStorage.setItem('details', JSON.stringify(data.result.data));
            }
        });
    }

    uploadAvatar(file): Observable<any> {
        const formData = new FormData();
        formData.set('file', file);
        return this.http.post(environment.ENDPOINTS.USER_AVATAR, GeneralResponse, formData, null, false, true);
    }
}
