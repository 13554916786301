export enum ResponseCodes {
  FIREBASE_ERROR = 323,
  USER_NOT_FOUND = 322,
  MINIMUM_PRODUCT_LIMIT = 321,
  MAXIMUM_PRODUCT_LIMIT = 320,
  TRACK_ORDER_NOT_FOUND = 319,
  USER_DETAIL_NOT_FOUND = 318,
  ADDRESS_NOT_FOUND = 317,
  ORDER_CAN_NOT_CANCEL = 316,
  PERMISSION_DENIED = 315,
  ORDER_NOT_FOUND = 314,
  BRANCH_NOT_WORKING_TIME = 313,
  CARRIER_NOT_WORKING_TIME = 312,
  DELIVERY_NOT_ACTIVE = 311,
  BRANCH_NOT_FOUND = 310,
  CART_NOT_FOUND = 311,
  COMPANY_TYPE_NOT_FOUND = 310,
  OK = 200,
  VALIDATION_ERROR = 711,
  USERNAME_OR_PASSWORD_IS_WRONG = 712,
  USERNAME_ALREADY_EXISTS = 713,
  ACCESS_DENIED = 714,
  METHOD_NOT_SUPPORTED = 715,
  FB_TOKEN_IS_INVALID = 304,
  COUNTRY_IS_WRONG = 305,
  LANGUAGE_IS_WRONG = 306,
  BANNED = 307,
  FB_TOKEN_INVALID = 308,
  USERNAME_NOT_VALID = 309,
  SMS_ERROR = 310,
  OTP_CODE_INVALID = 311,
  OTP_EXCEED_LIMIT = 312,
  USER_TOKEN_NOT_FOUND = 716,
  USER_CARD_NAME_ALREADY_EXISTS = 717,
  USER_CARD_EXCEED_LIMIT = 718,
  USER_CARD_SAVE_ECOMM_ERROR = 719,
  PAYMENT_TRANSACTION_NOT_FOUND = 720,
  ECOMM_CHECK_STATUS_ERROR = 721,
  USER_CARD_NOT_FOUND = 722,
  USER_CARD_SAVE_GET_TRANSACTION_ECOMM_ERROR = 723,
  USER_NOT_ACTIVE = 724,
  PRODUCT_NOT_FOUND = 725,
  PRODUCT_EXTRA_VAL_NOT_FOUND = 726,
  CARD_ID_NOT_BLANK = 727,
  ASSIGNED_USER_NOT_FOUND = 728,
  ECOMM_PAYMENT_ERROR = 729,
  CATEGORY_NOT_FOUND = 730,
  TAKEAWAY_NOT_ACTIVE = 731,
  FREE_DELIVERY_NOT_ACTIVE = 732,
  BRANCH_FREE_DELIVERY_NOT_WORKING_TIME = 733,
  ORDER_PRODUCT_NOT_FOUND = 734,
  SALE_ERROR = 735,
  YAXINDA_COURIER_EROR = 736,
  YAXINDA_COURIER_NOT_ACTIVE = 737,
  EXISTS_PAYMENT_PROCESS_BY_CARD = 738,
  PAY_AND_REVERSE_ERROR = 739,
  NOTIFICATION_NOT_FOUND = 740,
  TAG_NOT_FOUND = 741,
  USER_HAS_NOT_CREDIT = 742,
  SUBCATEGORY_NOT_FOUND = 743,
  SERVER_ERROR = 500
}

