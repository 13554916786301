import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, NavigationEnd, Router} from '@angular/router';
import {ExceptionHandlerService} from './service/utils/exception-handler.service';

declare const feather: any;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {
  title = 'Onbir Liqa';
  showSidebar = true;
  showNavbar = true;
  showExceptionModal = false;

  public constructor(private route: ActivatedRoute, private router: Router, private exceptionHandler: ExceptionHandlerService) {
    this.exceptionHandler.errorModalVisibilityChange.subscribe(value => {
      this.showExceptionModal = value;
    });
  }

  ngOnInit(): void {
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        setTimeout(_ => {
          feather.replace();
        }, 500);
        console.log(this.route.firstChild?.snapshot.data);
        this.showSidebar = this.route.firstChild?.snapshot?.data.showSideBar !== false;
        this.showNavbar = this.route.firstChild?.snapshot?.data.showNavbar !== false;
      }
    });
  }
}
