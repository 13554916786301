import {Component, OnInit} from '@angular/core';
import {AuthService} from '../../service/auth/auth.service';
import {NavigationService} from '../../service/utils/navigation.service';
import {Router} from '@angular/router';
import {UtilService} from '../../service/utils/util.service';

declare const $: any;

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.css']
})
export class NavbarComponent implements OnInit {
  sidebarNiceScroll: boolean;

  constructor(private authService: AuthService,
              private navigationService: NavigationService,
              private router: Router,
              public utilService: UtilService) {
  }

  ngOnInit(): void {
  }

  logout(): void {
    this.authService.logout();
  }

  openLeftSidebar(): void {
    const body = $('body');
    const w = $(window);

    if (w.outerWidth() <= 1024) {
      // body.removeClass('search-show search-gone');
      if (body.hasClass('sidebar-gone')) {
        body.removeClass('sidebar-gone');
        body.addClass('sidebar-show');
      } else {
        body.addClass('sidebar-gone');
        body.removeClass('sidebar-show');
      }

      this.updateSidebarNiceScroll();
    }
  }


  updateSidebarNiceScroll(): void {
  }

  getImage(): string {
    const isHave = this.utilService.getUserInfo().avatarPath !== null;
    if (!isHave) {
      return undefined;
    } else {
      return this.utilService.getImage(this.utilService.getUserInfo().avatarPath);
    }
  }
}
