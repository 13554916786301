import {Component, OnInit} from '@angular/core';

@Component({
  selector: 'app-left-sidebar',
  templateUrl: './left-sidebar.component.html',
  styleUrls: ['./left-sidebar.component.css']
})
export class LeftSidebarComponent implements OnInit {
  constructor() {
  }

  navItems = [
    {url: '/admin/menu', logo: 'fas fa-users-cog', role: 'ROLE_ADMIN', text: 'Admin Ana Səhifə'},
    {url: '/', logo: 'fas fa-home', role: 'ROLE_USER', text: 'Ana Səhifə'},
    {url: '/competition', logo: 'fas fa-trophy', role: 'ROLE_USER', text: 'Yarış'}
  ];

  ngOnInit(): void {

  }

  checkAccess(role): boolean {
    if (localStorage.getItem('authorities').indexOf(role) > -1) {
      return true;
    }
    return false;
  }
}
